<template>
  <div class="exclusiveClients-view">
    <div class="d-flex fs10 fwbold justify-content-center">
      客户企微数据
    </div>
    <commonTable :tableData="tableData" 
                 @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange"
                 @handleSelectionChange="handleSelectionChange"
                 :loading="loading"
                 :total="total"
                 >
      <template v-slot:table>
        <el-table-column prop="name" align="center" label="姓名" />
        <el-table-column
          prop="phone"
          align="center"
          label="手机号码"
          show-overflow-tooltip
        />
        <el-table-column prop="personalWx" align="center" label="微信号" />
        <el-table-column prop="inputDate" align="center" label="创建时间" />
        <el-table-column prop="inputDate" align="center" label="企业" />
        <el-table-column prop="inputDate" align="center" label="描述" />
        <el-table-column prop="customerRemark" align="center" label="来源" />
      </template>
    </commonTable>
    <div class="d-flex align-items-center justify-content-between mt30">
      <p class="fwbold">与客户企微聊天统计</p>
      <div class="pt10 pr20 pb10 pl20 w200 d-flex justify-content-between bgf5f6 fs7 hover_pointer">
        <div v-for="(item,index) in dateList"
             :class="currentIndex == index ? 'cblue' : ''"
             :key="index" 
             @click="dateChange(index)">
          {{item.title}}
        </div>
      </div>
    </div>
    <div class="bgf5f6 pt10 pl10 pr10 pb10 mt30">
      <div class="d-flex flex-wrap-y w100p borderbox">
        <div class="bge3e7 pt50 pl30 pb30 pt50 w24p mb10 chatDataBox mr10" 
             v-for="(item,index) in dataList" 
             :key="index"
             @click="chatDataBoxTap(index)"
             :class="currentChatData == index ? 'currentChatDataBox' : '' ">
          <p class="fs6 cA3">{{item.title}}</p>
          <p class="fs12 fwbold mt10">{{item.num   ? item.num : '--'}}</p>
        </div>
      </div>
      <div id="container">

      </div>
    </div>
    
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { Chart } from "@antv/g2";
import { getCustomerPC,getCompanyStaffList,moveCustomer } from "@/api/client";
export default {
  name: "exclusiveClients",
  components: {
    commonTable,
  },
  props:{
    detallId:{
      type :String,
      default:''
    },
  },
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      customerIds:'',
      total:0, //总页数
      pageNum:1,
      pageSize:10,
      loading:false,
      name:'',//查询条件 ： 电话名称
      followName:'',//查询条件 ： 销售员姓名
      dialogTableVisible:false, //成员弹窗
      dialogType:'移至成员',// 弹出框类型
      dialogPageNum:'',
      memberData:[] ,// 
      memberTotal: 0,
      dateList:[
        {
          title:'昨日'
        },
        {
          title:'近七日'
        },
        {
          title:'近30日'
        }
      ],
      dataList:[
        {
          title:'单聊总数',
          num:1
        },
        {
          title:'发送消息数',
          num:3
        },
        {
          title:'发送红包数',
          num:0
        },
        {
          title:'发送红包金额',
          num:0
        },
        {
          title:'已回复聊天占比',
          num:''
        },
        {
          title:'平均首次回复时长',
          num:''
        },
      ],
      data:[
        {
          amount: 1,
          city: "商城订单",
          date: "2022-02-08"
        },
        {
          amount: 2,
          city: "商城订单",
          date: "2022-02-08"
        },
        {
          amount: 3,
          city: "商城订单",
          date: "2022-02-08"
        },
        {
          amount: 4,
          city: "商城订单",
          date: "2022-02-08"
        },
      ],
      currentIndex:0,// 当前查询日期
      currentChatData:'',
      chart:''
    };
  },
  created(){
    this.getClientList()
    this.getMember()
    
  },
  mounted(){
    this.initComponent()
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.customerIds =  this.multipleSelection.map(item => item.customerId);
    },
    getClientList() {
      this.loading = true
      let data = {
        type:1,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        customerState: 3,
        cardId:this.detallId,
        name:this.name,
        followName:this.followName
      };
      getCustomerPC(data).then((res) => {
        this.loading = false
        this.tableData = res.data.pageInfo.list;
        this.tableData.forEach(v=>{
          v.selection = false
        })
        this.total = res.data.pageInfo.total;
      })
      .catch(err=>{
        this.tableData = []
        this.loading = false
      })
    },
    getMember(){
      let data = {
        pageNum: this.dialogPageNum,
        queryType:'web'
      }
        getCompanyStaffList(data)
        .then((res)=> {
            this.memberData = res.data.list;
            console.log(this.memberData)
            this.memberTotal = res.data.totalNumber
        });
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getClientList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getClientList()
    },
    //弹出框关闭
    dialogClose(){
      this.dialogTableVisible = false
    },
    //弹出框分页
    dialogCurrentChange(val){
      this.dialogPageNum = val
      this.getMember()
    },
    //确认人员
    turnOverMember(val){
      if(!val){
        this.$message({
          title: "警告",
          message: "请先选择移交的成员",
          type: "warning",
        });
      }
      let data ={
        customerIds:this.customerIds.join(',') || this.customerIds,
        ascriptionId:val,
        customerState: 1,
      }
      moveCustomer(data)
      .then(res =>{
        if(res.code == 200){
          this.$message({
            message: "移交成功",
            type: "success",
          });
          this.getClientList()
          this.dialogTableVisible = false
        }
      })
      .catch(err=>{
        if(err.code == 201){
          this.$message({
            message: err.message,
            type: "warning",
          });
          this.dialogTableVisible = false
        }
      })
    },
    dateChange(index){
      
      this.currentIndex = index
      this.resetChart()
    },
    detall_tap(id){
      this.$router.push('../../customerDetall?id=' + id)
    },
    edit_tap(id){
      this.$router.push('../../customerInput?id=' + id)
    },
    initComponent() {
      const chart = new Chart({
        container: "container",
        autoFit: true,
        height: 500,
      });
      chart.data(this.data);
      chart.scale({
        month: {
          date: [0, 1],
        },
        amount: {
          nice: true,
        },
      });

      chart.tooltip({
        showCrosshairs: true,
        shared: true,
      });

      chart.axis("amount", {
        label: {
          formatter: (val) => {
            return val;
          },
        },
      });

      chart.line().position("date*amount").color("city").shape("smooth");

      chart.point().position("date*amount").color("city").shape("circle");

      chart.render();
      this.chart = chart
    },
    chatDataBoxTap(index){
      this.currentChatData = index
      this.resetChart()
    },
    resetChart(){//重置eChart
      if (this.chart) {
        this.chart.render();
      } else {
        this.initComponent()
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mr-16 {
  margin-right: 16px;
}
.exclusiveClients-view {
  background: #ffffff;
  border-radius: 0px 0px 2px 2px;
  padding: 24px;
}
.operation-left {
  .el-button {
    width: 96px;
    height: 32px;
    background: $color-1;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    &:active {
      border-color: $color-1;
    }
  }
}
.operation-right {
  .el-button--primary {
    background: $color-1;
    border-color: $color-1;
  }
  .export {
    border: 1px solid rgba(81, 203, 205, 0.4);
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: $color-1;
  }
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}
.chatDataBox{
  background: #d8e3e7;
  transition: all 0.5s;
  
}
.currentChatDataBox{
  background: rgba(81, 203, 205, 0.15);
  transition: all 0.5s;
  p{
    color: $color-1;
  }
}
</style>